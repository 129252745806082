import { AppConfig } from "src/app/app.configuration";

export class ApiConstants {
    // url
    //public static url: string = AppConfig.settings.webApi.url;

    // paths
    public static pathAuthToken : string = '/auth/token';
    public static pathAuthRefreshToken : string = '/auth/refresh';
    public static pathUser : string = "/auth/currentuser";
    public static pathExternalUser : string = "/auth/external_login";
    public static pathRegister : string = "/user/register";
    public static pathHospitals : string = "/user/hospitals";
    public static pathRequestResetPassword : string = "/user/request_reset_password";
    public static pathResetPassword : string = "/user/reset_password";

    //Marketing
    public static pathSearch : string = "/marketing/search";
    public static pathFavourites : string = "/marketing/favourites";
    public static pathFavouriteDetail : string = "/marketing/get_favourite_detail";
    public static pathSearchCount : string = "/marketing/search_count";
    public static pathFilterDocuments : string = "/marketing/filter_documents";
    public static pathMetadata : string = "/marketing/metadata";
    public static pathDocumentPreview: string = "/marketing/document_preview";
    public static pathgetDocumentView: string = "/marketing/document_view";
    public static pathDownloadDocument: string = "/marketing/download_document";
    public static pathShareDocument: string = "/marketing/share_document";
    public static getDocumentById: string = "/marketing/get_document_by_id";

    //Favourite
    public static pathManageFavourite : string = "/user/manage_favourite";

    //Metric
    public static pathMetrics : string = "/metric/get_metrics";
    public static pathMetricUsers : string = "/metric/get_metric_users";
    public static pathInitMetricUsers : string = "/user/init_metric_users";
    public static pathMetricUrl : string = "/metric/get_metric_url";
    public static pathExportMetrics : string = "/metric/export_metrics";

    //Appointment
    public static pathBusinessUnitsPreview: string = "/appointment/business_units";
    public static pathComercialDelegatesPreview: string = "/appointment/comercial_delegates";
    public static pathNewAppointment: string = "/appointment/new";
}
