import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AppConfig } from "src/app/app.configuration";

import { Title } from '@angular/platform-browser';
import { filter, map, switchMap } from "rxjs/operators";
import { Subscription } from 'rxjs/internal/Subscription';
import { SubscriptionUtil } from './core/utils/subscription.util';
import { Observable } from 'rxjs/internal/Observable';
import { UserService } from './core/services/user.service';
import { User } from './core/models/user.model';

/* Como añadir servicio de google analytics: https://morioh.com/p/ea20d3ed48aa */
declare let gtag: (property: string, value: any, configs: any) => {};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

  subscription: Subscription;
 
  constructor (private router: Router, private activeRoute: ActivatedRoute, private title: Title, private userService: UserService) {}
  
  ngOnInit(): void {
    const observablePipeEvents: Observable<any> 
      = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activeRoute),
        map(() => {
          let child = this.activeRoute.firstChild;
          while (child) {
              if (child.firstChild) {
                  child = child.firstChild;
              } else if (child.snapshot.data && child.snapshot.data['title']) {
                  return child;
              } else {
                  return null;
              }
          }
          return null;
        }),
        switchMap(route => route.data),
        map((data) => { return data; })
      );
      
      observablePipeEvents
        .subscribe(async (data) => { 
          if(data)
          {
            const title: string = data.title ? data.title : '';
            const user: User = await this.userService.attemptCurrentUser();
            const user_id = user && user.email ? user.email : '';
            
            // Notificar a google analytics 4
            gtag('config', AppConfig.settings.googleAnalytics.id_google_analytics_4, {
              page_title: title,
              page_location: `${AppConfig.settings.webPortal.url}${this.router.url}`,
              page_path: this.router.url,
              user_id: user_id,
            });
            
            //window['ga-disable-UA-XXXXXX-Y'] = true;
            // Notificar a universal analytics
            gtag('config', AppConfig.settings.googleAnalytics.id_universal_analytics, {
              page_title: title,
              page_location: `${AppConfig.settings.webPortal.url}${this.router.url}`,
              page_path: this.router.url,
              user_id: user_id,
            });

            // Actualizar titulo de la página
            const pageTitle : string = `Fresenius - ${title}`;
            this.title.setTitle(pageTitle);
          }
        });
  }

  ngOnDestroy(): void {
    SubscriptionUtil.SafeUnsubscribe(this.subscription);
  }
}
