import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fr-layout',
  templateUrl: './layout.component.html',
})
export class LayoutComponent implements OnInit {
    
    ngOnInit() {
        
    }
    
}