import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UserService } from 'src/app/core/services/user.service';
import { RememberModalComponent } from './remember-modal.component';
import { RegisterModalComponent } from './register-modal.component';
import { ToastrService } from 'ngx-toastr';
import Utils from "src/app/logic/utils/utils";
import { LoaderService } from 'src/app/core/services/loader.service';
import { Subscription } from 'rxjs';
import { SubscriptionUtil } from 'src/app/core/utils/subscription.util';
import { AppConfig } from 'src/app/app.configuration';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit, OnDestroy {
  
  loginForm: FormGroup;
  isSubmitting = false;
  subscription: Subscription;
  private urlRedirect: string = "auth/marketing";

  get showRegister(): boolean { 
    return AppConfig.settings.portal.showRegister;
  }

  /* Validadores */
  get requiredUser(): boolean {
    return !this.loginForm.get('user').valid && this.loginForm.get('user').touched;
  }

  get requiredPassword(): boolean {
    return !this.loginForm.get('password').valid && this.loginForm.get('password').touched;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute, 
    private modalService: BsModalService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private loaderService: LoaderService) {
    this.loginForm = this.formBuilder.group({
      user: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  async ngOnInit(): Promise<void> {
    if(this.userService.getCurrentUser()) {
      await this.router.navigate(['/auth/marketing']);
    } else if (await this.userService.attemptCurrentUser()) {
      await this.router.navigate(['/auth/marketing']);
    }

    // Url que el usuario ha intentado acceder antes de logear.
    this.route.queryParams.subscribe(params => {
      if (params['page']) {
        this.urlRedirect = params['page'];
      }
    });

    this.subscription = this.modalService.onHide.subscribe((reason: string | any) => {
      if (typeof reason == 'string' && reason == 'backdrop-click') {
        Array.from(document.getElementsByClassName('modal-backdrop')).forEach((item) => {
          item.parentElement.removeChild(item);
        });
      }
    })
  }

  async ngOnDestroy(): Promise<void> {
    if(this.subscription) {
      SubscriptionUtil.SafeUnsubscribe(this.subscription);
    }
  }

  async login() : Promise<void>{
    await this.router.navigate(['/public/login-external'], { queryParams: { page: this.urlRedirect } });
  }

  async submitLoginForm() : Promise<void> {
    const credentials = this.loginForm.value;
    
    try{
      this.loaderService.show();
      this.isSubmitting = true;
      await this.userService.attemptAuth(credentials.user, credentials.password);
      this.isSubmitting = false;
      this.loaderService.hide();
      await this.router.navigateByUrl(this.urlRedirect);
    }
    catch(error) {
      this.isSubmitting = false;
      this.loaderService.hide();
      this.toastr.error(error, 'Error');
      await Utils.delay(4000);
      this.toastr.clear();
    }
  }

  async onRemember(): Promise<void> {
    const modalRef: BsModalRef = this.modalService.show(RememberModalComponent);
    modalRef.content.onConfirm = async () => {
      /* to do */
    }
  }
  
  async onRegister(): Promise<void> {
    const modalRef: BsModalRef = this.modalService.show(RegisterModalComponent, { class: 'modal-xl'});
    modalRef.content.onConfirm = async () => {
      /* to do */
    }
  }

}
