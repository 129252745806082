import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserApiService } from 'src/app/logic/services/user.service';
import { Register } from 'src/app/logic/models/register.model';
import { ToastrService } from 'ngx-toastr';
import Utils from "src/app/logic/utils/utils";
import { LoaderService } from 'src/app/core/services/loader.service';
import { Hospital } from 'src/app/logic/models/User/hospital.model';
import { utils } from 'protractor';


@Component({
  selector: 'register-modal',
  templateUrl: './register-modal.component.html',
})
export class RegisterModalComponent implements OnInit {
  public ErrorMessage: string;
  public registerForm: FormGroup;
  public checkPersonalData: Boolean;
  public hospitals: Hospital[] = [];
  public workFieldValue = "";
  public hospitalListName: string[] = [''];
  private rowLimithospitals: number = 10;

  /* Validadores */
  public get requiredName(): boolean {
    return !this.registerForm.get('name').valid && this.registerForm.get('name').dirty;
  }

  public get requiredLastnName(): boolean {
    return !this.registerForm.get('lastname').valid && this.registerForm.get('lastname').dirty;
  }

  public get requiredUser(): boolean {
    return !this.registerForm.get('user').valid && this.registerForm.get('user').dirty;
  }

  public get requiredEmail(): boolean {
    return this.registerForm.get('email').hasError('required') && this.registerForm.get('email').dirty;
  }

  public get invalidEmail(): boolean {
    return this.registerForm.get('email').hasError('email');
  }

  public get requiredPassword(): boolean {
    return this.registerForm.get('password').hasError('required') && this.registerForm.get('password').dirty;
  }

  public get invalidFormatPassword(): boolean {
    return this.registerForm.get('password').hasError('error');
  }

  public get requiredPhone(): boolean {
    return this.registerForm.get('phone').hasError('required') && this.registerForm.get('phone').dirty;
  }

  public get validPhone(): boolean {
    return !this.registerForm.get('phone').hasError('error');
  }

  public get requiredCompany(): boolean {
    return this.registerForm.get('company').hasError('required') && this.registerForm.get('company').dirty;
  }

  public get requiredSpeciality(): boolean {
    return this.registerForm.get('speciality').hasError('required') && this.registerForm.get('speciality').dirty;
  }

  public get requiredcheckPersonalData(): boolean {
    return this.registerForm.get('checkPersonalData').hasError('required') && this.registerForm.get('checkPersonalData').dirty;
  }

  constructor(
    public  bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private userApiService: UserApiService,
    private router: Router,
    private toastr: ToastrService,
    private loaderService: LoaderService,
    
    ) {
      this.registerForm = new FormGroup({
        name: new FormControl('', [Validators.required]),
        lastname: new FormControl('', [Validators.required]),
        user: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.email, Validators.required]),
        password: new FormControl('', [Validators.required]),
        //confirmPassword: ['', Validators.required], 
        phone: new FormControl('', [Validators.required]),
        company: new FormControl('', [Validators.required]),
        collegiate_name: new FormControl('', []),
        speciality: new FormControl('', [Validators.required]),
        checkPersonalData: new FormControl('', []),
        //state: this.stateCtrl,
      }, {});

      this.registerForm.setValidators([
        this.patternValidatorPassword(),
        this.validatorPhoneNumber(),
        this.validatorCheckBoxPersonalData()
      ]);
    }
  ngOnInit(): void {
  }
  close() {
  //myclose instalar=>  ng add @ng-bootstrap/ng-bootstrap
};

public async submitRegisterForm() : Promise<void> {
  const Form = this.registerForm.value; 
  let message = null;

  if (this.registerForm.valid) {
    
    const data: Register = {
      first_name: this.registerForm.controls['name'].value,
      last_name: this.registerForm.controls['lastname'].value,
      username: this.registerForm.controls['user'].value,
      email: this.registerForm.controls['email'].value,
      password: this.registerForm.controls['password'].value,
      phone: this.registerForm.controls['phone'].value,
      work_center: this.registerForm.controls['company'].value,
      collegiate_name: this.registerForm.controls['collegiate_name'].value,
      work_field: this.registerForm.controls['speciality'].value,
    };

    try {
      await this.loaderService.show();
      message = await this.userApiService.register(data);
      await this.loaderService.hide();
      this.toastr.success(message.toString(), 'Exito!');
      await Utils.delay(2000);
      window.location.reload();
    }
    catch(error) {
      this.toastr.error(error, 'Error');
      await this.loaderService.hide();
      await Utils.delay(2000);
      this.toastr.clear();
    }
  } else {
    Object.keys(this.registerForm.controls).forEach(key => {
      this.registerForm.controls[key].markAsDirty();
    });
    this.registerForm.updateValueAndValidity();
  }
}

public async onInputHospital(value: string) : Promise<void> {
  if (value.trim() != '') {
    this.hospitals = await this.userApiService.LoadHospitals(value, this.rowLimithospitals);
    if (this.hospitals.length > 0) {
      this.hospitalListName = this.hospitals.map(function(a) {return a.name;});
    } else {
      this.hospitalListName = [''];
    }
  }
}

public onSelectValueWorkCenter (result) {
  this.workFieldValue = result;
  this.registerForm.controls['company'].setValue(result);
}

public isCheckedPersonalDataValue() : Boolean {
  return this.registerForm.controls['checkPersonalData'].value;
}

private validatorPhoneNumber() : ValidatorFn {
  return (group: FormGroup) : ValidationErrors => {
    const regSpanishNumber = new RegExp("^[679]{1}[0-9]{8}$");

    let phoneInput = group.controls['phone'];

    if (!phoneInput.value) 
    {
      phoneInput.setErrors({required: true});
      return;
    }

    var str = phoneInput.value.toString().replace(/\s/g, '');
    /*Que el numero tenga el formato correcto*/ 
    if (!regSpanishNumber.test(str)) {
      phoneInput.setErrors({error: true});
      return;
    }
  };
}

private patternValidatorPassword() : ValidatorFn {
  return (group: FormGroup) : ValidationErrors => {
    const regUpperCaseChar = new RegExp("(?=.*[A-Z])");
    const regHasNumber = new RegExp("(?=.*[0-9])");
    const regHasSpecialchar = new RegExp("(?=.*[\"!@#\$%\^\&*\)\(+=._-])");
    const maxLength = 6;

    let passwordInput = group.controls['password'];
    let errors = {};
    this.ErrorMessage = "";
    let arrayErrors = [];
    let hasError = false;

    if (!passwordInput.value) 
    {
      passwordInput.setErrors({required: true});
      return;
    }

    /*Que contenga almenos una mayuscula*/ 
    if (!regUpperCaseChar.test(passwordInput.value)) {
      arrayErrors.push("Una mayúscula");
      hasError = true;
    }

    /*Que contenga almenos un numero*/ 
    if (!regHasNumber.test(passwordInput.value)) {
      arrayErrors.push("un número");
      hasError = true;
    }

    /*Que contenga almenos un caracter especial*/ 
    if (!regHasSpecialchar.test(passwordInput.value)) {
      arrayErrors.push("un carácter especial");
      hasError = true;
    }

    /*Que contenga almenos 6 caracteres*/ 
    if (passwordInput.value.length < maxLength) {
      arrayErrors.push("un minimo de 6 caracteres");
      hasError = true;
    }

    if (hasError)
      errors = {error: true};
    else
      errors = null;

    this.ErrorMessage = "* La contraseña debe tener almenos: " + arrayErrors.join(', ') + ".";
    passwordInput.setErrors(errors);
    return;
  };
}

private checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
  return (group: FormGroup) => {
    let passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
    if (passwordInput.value !== passwordConfirmationInput.value) {
      return passwordConfirmationInput.setErrors({notEquivalent: true})
    }
    else {
        return passwordConfirmationInput.setErrors(null);
    }
  }
}

private validatorCheckBoxPersonalData() : ValidatorFn {
  return (group: FormGroup) : ValidationErrors => {
    let checkBox = group.controls['checkPersonalData'];

    if (!checkBox.value) 
    {
      checkBox.setErrors({required: true});
      return;
    }
  };
}

}