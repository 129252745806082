import { NgModule } from "@angular/core";
import { TimepickerConfig, TimepickerModule } from "ngx-bootstrap/timepicker";
import { NgxDocViewerModule } from "ngx-doc-viewer";
import { SecurePipe } from "src/app/shared/pipes/secure.pipe";
import { SharedModule } from "src/app/shared/shared.module";
import { AppointmentComponent } from "./appointment/appointment.component";
import { AuthRoutingModule } from "./auth-routing.module";
import { AuthComponent } from "./auth.component";
import { DocumentComponent } from "./document/document.component";
import { MarketingComponent } from "./marketing/marketing.component";
import { FavouriteComponent } from "./favourite/favourite.component";
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { MetricsComponent } from "./metrics/metrics.component";
import { AgGridModule } from "ag-grid-angular";
import { NgbPopoverModule } from "@ng-bootstrap/ng-bootstrap";
import { PopoverComponent } from "./popover/popover.component";

@NgModule({
    imports: [
        SharedModule,
        AuthRoutingModule,
        AgGridModule,
        NgxDocViewerModule,
        TimepickerModule.forRoot(),
        NgbPopoverModule,
        
        NgxExtendedPdfViewerModule
    ],
    declarations: [
        AuthComponent,
        AppointmentComponent,
        MarketingComponent,
        FavouriteComponent,
        MetricsComponent,
        PopoverComponent,
        DocumentComponent
    ],
    entryComponents: [PopoverComponent],
    providers: [
        SecurePipe,
        TimepickerConfig
    ]
})
export class AuthModule { }
