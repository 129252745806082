import { Injectable } from "@angular/core";
import { Guid } from "guid-typescript";
import { ApiConstants } from "src/app/core/constants/api.constants";
import { ApiService } from "src/app/core/services/api.service";
import { MetricInfo } from "../models/Metric";
import { MetricSearch } from "../models/MetricSearch.model";
import { MetricUser } from "../models/MetricUser";

@Injectable()
export class MetricService {
    constructor(private apiService: ApiService)
    {

    }

    public async getMetrics(search: MetricSearch) : Promise<MetricInfo[]> {
      return await this.apiService.post(ApiConstants.pathMetrics, search);
    }

    public async getMetricUsers(businessUnitId: Guid) : Promise<MetricUser[]> {
      return await this.apiService.get(ApiConstants.pathMetricUsers, {businessUnitId});
    }

    public async getUrlMetrics(docUniqueId: Guid) : Promise<MetricInfo> {
      return await this.apiService.get(ApiConstants.pathMetricUrl, {docUniqueId});
    }

    public async exportMetrics(businessUnitId: Guid) : Promise<Blob> {
      return await this.apiService.getFile(ApiConstants.pathExportMetrics, {businessUnitId});
    }
}
