import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from "src/app/core/models/user.model";
import { AppConfig } from 'src/app/app.configuration';
import { UserService } from 'src/app/core/services/user.service';
import { MetricUser } from 'src/app/logic/models/MetricUser';

@Component({
  selector: 'fr-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent  implements OnInit {

    displayMetrics = false;

    constructor(private userService: UserService, private router: Router) { }

    get showAppointmentRequest(): boolean {
      return AppConfig.settings.portal.showAppointmentRequest;
    }

    ngOnInit() {
      this.userService.initMetricUsers().then((metricUsers: MetricUser[]) => {
        this.userService.attemptCurrentUser().then((u: User) => {
          if (metricUsers.find(m => m.username.toLowerCase() == u.email.toLowerCase()) != undefined) {
            this.displayMetrics = true;
          }
        });
      });
    }


    async logout() {
      try{
        await this.userService.purgeAuth();
      } catch(error) {

      }
      this.router.navigate(['/']);
    }



    public loadFavourites() : void {
      if (this.router.url == '/auth/favourite') {


        window.location.reload();
      } else {
        this.router.navigate(['/auth/favourite']);


      }
    }
}
