import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from 'src/app/core/services/loader.service';
import { RequestResetPassword } from 'src/app/logic/models/User/request-reset-password.model';
import { UserApiService } from 'src/app/logic/services/user.service';
import Utils from 'src/app/logic/utils/utils';

@Component({
  selector: 'remember-modal',
  templateUrl: './remember-modal.component.html',
})
export class RememberModalComponent implements OnInit {
  RememberForm: FormGroup;

  get invalidEmail(): boolean {
    return this.RememberForm.get('email').hasError('errorEmailFormat');
  }

  constructor(
    public  bsModalRef: BsModalRef,
    private userApiService: UserApiService,
    private toastr: ToastrService,
    private loaderService: LoaderService, 
    private router: Router, 

    ){
    this.RememberForm = new FormGroup({
      email: new FormControl('', [Validators.required]),
    });

    this.RememberForm.setValidators([
      this.validatorEmail()
    ]);
  }

  ngOnInit(): void {
  }
  close() {
  //myclose instalar=>  ng add @ng-bootstrap/ng-bootstrap
  };

  async submitRememberForm() : Promise<void> {
    if (this.RememberForm.valid) {
      const data: RequestResetPassword = {
        email: this.RememberForm.controls['email'].value,
      }
      
      try {
        
        await this.loaderService.show();
        const message: string = await this.userApiService.RequestResetPassword(data);
        await this.loaderService.hide();
        this.toastr.success(message, 'Éxito!');
        await Utils.delay(1000);
        window.location.reload();
      
      } catch (error) {
        
        this.toastr.error(error, 'Error');
        await Utils.delay(1000);
        await this.loaderService.hide();
      }
    }
  }

  validatorEmail() : ValidatorFn {
    return (group: FormGroup) : ValidationErrors => {
      const regularExpresionEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  
      let emailInput = group.controls['email'];
  
      if (!emailInput.value) 
      {
        emailInput.setErrors({required: true});
        return;
      }

      /*Que el email tenga el formato correcto*/ 
      if (!regularExpresionEmail.test(emailInput.value)) {
        emailInput.setErrors({errorEmailFormat: true});
        return;
      }
    };
  }
}