import { Component, SecurityContext } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from 'ag-grid-community';
import { DocumentInfo } from "src/app/logic/models/Document.model";
import { MarketingService } from "src/app/logic/services/marketing.services";
import StringUtils from "src/app/logic/utils/string.utiles";
import { DocumentExtension } from "src/app/shared/components/document-card/document-extension";
@Component({
  selector: "app-popover",
  templateUrl: "./popover.component.html",
})


export class PopoverComponent implements ICellRendererAngularComp {
  imageLoaded: boolean = false;

  document: DocumentInfo = {} as DocumentInfo;
  image: string;

  constructor(
    private marketingService: MarketingService,
    private sanitizer: DomSanitizer,
    ) { }

  agInit(params: ICellRendererParams<any, number>): void {
    if(params.data != null && params.data.documentId > 0){
      this.document.title = params.data.title;
      this.marketingService.getDocumentPreview(params.data.documentId).then((b64: object) => {
        const img = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + b64));
        this.image = img;
        this.imageLoaded = true;
      });
    }
  }

  refresh(params: ICellRendererParams) {
    return false;
  }

  public onImgError(event): void {
    const extension: string = StringUtils.getExtension(this.document.title);
    const src: string = this.getPortafolioImgByExtension(extension);
    event.target.src = src;
   }

   public getPortafolioImgByExtension(extension: string): string {
      const PATHTOPORTAFOLIO =  "../assets/img/portfolio";
      switch(extension.toLowerCase()) {
        case DocumentExtension.WORDX:
        case DocumentExtension.WORD: {
          return `${PATHTOPORTAFOLIO}/imagen.svg`;
        }
        case DocumentExtension.EXCELX:
        case DocumentExtension.EXCEL: {
          return `${PATHTOPORTAFOLIO}/imgExcel.svg`;
        }
        case DocumentExtension.PDF: {
          return `${PATHTOPORTAFOLIO}/imgPdf.svg`;
        }
        case DocumentExtension.POWERPOINTX:
        case DocumentExtension.POWERPOINT:
        case DocumentExtension.POWERPOINTMACRO: {
          return `${PATHTOPORTAFOLIO}/imgPpt.svg`;
        }
        case DocumentExtension.VIDEOMP4:
        case DocumentExtension.VIDEOAVI:{
          return `${PATHTOPORTAFOLIO}/imgVideo.svg`;
        }
        case DocumentExtension.IMAGENJPG:
        case DocumentExtension.IMAGENPNG:{
          return `${PATHTOPORTAFOLIO}/imgPhoto.svg`;
        }
        default: {
          return `${PATHTOPORTAFOLIO}/imagen.svg`;
        }
    }
   }

}


