import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from 'src/app/core/services/loader.service';
import { Appointment } from 'src/app/logic/models/Appointment.model';
import { BusinessUnitList } from 'src/app/logic/models/BusinessUnitList';
import { AppointmentServices } from 'src/app/logic/services/appointment.services';
import {formatDate} from '@angular/common';
import { ComercialDelegate } from 'src/app/logic/models/Appointment/ComercialDelegate.model';

@Component({
  selector: 'appointment',
  templateUrl: './appointment.component.html',
})
export class AppointmentComponent implements OnInit {
  AppointmentForm: FormGroup;
  businessUnitList: BusinessUnitList;
  currentDate: string;
  minTime: Date = new Date();
  isMeridian: boolean = false;
  listComercialDelegates: ComercialDelegate[];

  /* Validadores */
  get isEmptydDate(): boolean {
    return this.AppointmentForm.get('date').hasError("required") && this.AppointmentForm.get('date').touched;
  }

  get isEmptyStartTime(): boolean {
    return this.AppointmentForm.get('time_start').hasError("required") && this.AppointmentForm.get('time_start').dirty;
  }

  get isStartTimeInvalid(): boolean {
    return this.AppointmentForm.get('time_start').hasError("hourError") && this.AppointmentForm.get('time_start').dirty;
  }

  get isEmptyEndTime(): boolean {
    return this.AppointmentForm.get('time_end').hasError("required") && this.AppointmentForm.get('time_end').dirty;
  }

  get isEndTimeInvalid(): boolean {
    return this.AppointmentForm.get('time_end').hasError("hourError") && this.AppointmentForm.get('time_end').dirty;
  }

  get isEmptyAppointmentType(): boolean {
    return this.AppointmentForm.get('appointment_type').hasError("required") && this.AppointmentForm.get('appointment_type').touched;
  }

  get isEmptyBusinessUnit(): boolean {
    return this.AppointmentForm.get('business_unit').hasError("required") && this.AppointmentForm.get('business_unit').touched;
  }

  // Getter method to access formcontrols
  get appointment() {
    return this.AppointmentForm.get('appointment_type');
  }

  get businessUnits() {
    return this.AppointmentForm.get('business_unit');
  }

  get comercialDelegate() {
    return this.AppointmentForm.get('comercial_delegate');
  }

  constructor(
    private appointmentServices: AppointmentServices,
    private loaderService: LoaderService,
    private toastr: ToastrService,
    private datePipe: DatePipe
    ) {
      this.AppointmentForm = new FormGroup({
        date: new FormControl('', [Validators.required]),
        time_start: new FormControl('', [Validators.required]),
        time_end: new FormControl('', [Validators.required]),
        comercial_delegate: new FormControl('', []),
        appointment_type: new FormControl('', [Validators.required]),
        business_unit: new FormControl('', [Validators.required]),
        comments: new FormControl('', []),
      });

      this.AppointmentForm.setValidators([
        this.validatorHours()
      ]);
    }
    async ngOnInit(): Promise<void> {
      this.currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
      this.minTime.setHours(this.minTime.getHours());
      this.minTime.setMinutes(this.minTime.getMinutes());

      this.loadEvent();

      await this.loaderService.show();
      try{
        this.businessUnitList = await this.appointmentServices.getListBusinessUnits();
      } catch (error) {
        this.toastr.error("No se han podido recuperar las unidades de negocio");
      }
      await this.loaderService.hide();

      // Ponemos el valor de la lista comercial delegate 0 por defecto.
      this.comercialDelegate.setValue("0");
    }
    close() {
    };

  public async submitAppointmentForm() : Promise<void> {
    
    if (this.AppointmentForm.valid) {

      const datePipeFormat: string = "dd/MM/yyyy";
      const momentFormat: string = "DD/MM/yyyy";

      const date = this.datePipe.transform(this.AppointmentForm.controls['date'].value, datePipeFormat);
      const time_start: Date = this.AppointmentForm.controls['time_start'].value;
      const time_end: Date = this.AppointmentForm.controls['time_end'].value;

      let comercial_delegate: string;
      this.listComercialDelegates.forEach(comercialDelegate=> {
        if (this.comercialDelegate.value == comercialDelegate.id) {
          comercial_delegate = comercialDelegate.email;
        }
      });

      const data: Appointment = {
        start_date:  moment(date, momentFormat).add(time_start.getHours(), 'hours').add(time_start.getMinutes(), 'minutes').toDate(),
        end_date:  moment(date, momentFormat).add(time_end.getHours(), 'hours').add(time_end.getMinutes(), 'minutes').toDate(),
        appointment_type: Number.parseInt(this.appointment.value),
        comercial_delegate: comercial_delegate,
        business_unit: this.businessUnits.value,
        comments: this.AppointmentForm.controls['comments'].value,
      };

      try {
        await this.loaderService.show();
        const message: string = await this.appointmentServices.postNewAppointment(data);
        await this.loaderService.hide();
        this.toastr.success(message, 'Exito!');
      }
      catch(error) {
        this.toastr.error(error, 'Error');
        await this.loaderService.hide();
      }
    }
  }

  public async onChange(businessUnitValue) {
    this.loaderService.show();
    try{
    this.listComercialDelegates = await this.appointmentServices.getListComercialDelegates(businessUnitValue);
    } catch (error) {
      this.toastr.error("No se han podido recuperar los delegados comerciales");
    }
    this.loaderService.hide();
    this.comercialDelegate.setValue("0");
  }

  private resetTime() : void {
    this.AppointmentForm.controls['time_start'].setValue(null);
    this.AppointmentForm.controls['time_start'].updateValueAndValidity();
    this.AppointmentForm.controls['time_start'].markAsPristine();

    this.AppointmentForm.controls['time_end'].setValue(null);
    this.AppointmentForm.controls['time_end'].updateValueAndValidity();
    this.AppointmentForm.controls['time_end'].markAsPristine();
  }

  private loadEvent() : void {
    this.AppointmentForm.controls['date'].valueChanges.subscribe((value) => {
      this.resetTime();
    });
  }

  private validatorHours() : ValidatorFn {
    return (group: FormGroup) : ValidationErrors => {
      const datePipeFormat: string = "dd/MM/yyyy";

      let dateValue = group.controls['date'];
      let startHour = group.controls['time_start'];
      let endHour = group.controls['time_end'];
      let hasError: boolean = false;

      const date: string = this.datePipe.transform(dateValue.value, datePipeFormat);
      const today: string = this.datePipe.transform(new Date(), datePipeFormat);
      const dateStart: Date = startHour.value;
      const dateEnd: Date = endHour.value;

      if(!dateStart) {
        hasError = true;
        startHour.setErrors({required: true});
        return;
      }

      if(!dateEnd) {
        hasError = true;
        endHour.setErrors({required: true});
        return;
      }

      if (date == today) {
        this.currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
        this.minTime = new Date();
        this.minTime.setHours(this.minTime.getHours());
        this.minTime.setMinutes(this.minTime.getMinutes());
      } else {
        this.minTime = null;
      }
      
      if ((dateStart && dateEnd) && (dateStart >= dateEnd) && (startHour.dirty && endHour.dirty)) 
      {
        hasError = true;
        startHour.setErrors({hourError: true});
        endHour.setErrors({hourError: true});
        return;
      } 

      if (!hasError) {
        startHour.setErrors(null);
        endHour.setErrors(null);
      }
    };
  }
}