import { Component, OnInit } from '@angular/core';
import { DocumentSearch } from 'src/app/logic/models/DocumentSearch.model';
import { Tag } from 'src/app/logic/models/Tag.model';
import { MarketingService } from 'src/app/logic/services/marketing.services';
import { DocumentInfo } from 'src/app/logic/models/Document.model';
import { TagList } from 'src/app/logic/models/TagList.model';
import { LoaderService } from 'src/app/core/services/loader.service';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/core/models/user.model';
import { UserService } from 'src/app/core/services/user.service';
import { AppConfig } from 'src/app/app.configuration';
import { FilterData } from 'src/app/logic/models/FilterData';
import { $ } from 'protractor';
import { SearchResult } from 'src/app/logic/models/SearchResult';
import { count } from 'console';
import { TagInfo } from 'src/app/logic/models/TagInfo.model';
import * as moment from 'moment';

class ClickableTagList {
  public tag: TagList;
  public selected: boolean;
  public visible: boolean;
  public selectionType: string;
}

@Component({
  selector: 'marketing',
  templateUrl: './marketing.component.html',
})
export class MarketingComponent implements OnInit {
  documents: DocumentInfo[] = [];
  documentsTotalCount: number = 0;
  appTagLists: ClickableTagList[] = [];
  userTagLists: ClickableTagList[] = [];
  tipoDocumentosTags: Tag[];
  productosTags: Tag[];
  serviciosTags: Tag[];
  patologiasTags: Tag[];
  userBusinessUnit: Tag[] = [];

  documentSearch: DocumentSearch = {
    search: "",
    metadata: [],
    searchJustByTitle: false,
    skip_doc_id: 0,
    publicationDate: '',
    pageNumber: 1,
    take: AppConfig.settings.document.numberOfDocuments
  };

  load_more: boolean = true;
  isLoading: boolean = true;
  user: User;

  constructor(
    private marketingService: MarketingService,
    private loaderService: LoaderService,
    private userService: UserService,
    private toastr: ToastrService) { }

  async ngOnInit(): Promise<void> {
    this.loaderService.show();

    this.userService.attemptCurrentUser().then((u: User) => {
      this.user = u;
    });

    this.getMetadata().then((tags: TagList[]) => {
      this.appTagLists = tags.map((tag: Tag) => {
        let filterType = 'and';
        switch (tag.name) {
          case 'UnidadDeNegocio':
          case 'Servicios':
          case 'Patologias':

            break;
          case 'TipoDeDocumento':
          case 'Productos':
            filterType = 'or';
            break;
        }
        tag.selectionType = filterType;

        return {
          tag: tag,
          selected: tag.selected,
          visible: false
        } as ClickableTagList
      });

      //Filtro las unidades de negocio selected = true
      const businessUnitTags: ClickableTagList = this.appTagLists.filter((v: ClickableTagList) => {
        return v.tag.name === 'UnidadDeNegocio';
      })[0];
      if (businessUnitTags) {
        const selectedBusinessUnitTags: Tag[] = businessUnitTags.tag.tags.filter((t: Tag) => {
          return t.selected;
        });
        if (selectedBusinessUnitTags.length > 0) {
          const tags: TagList = {
            name: businessUnitTags.tag.name,
            id: businessUnitTags.tag.id,
            tags: selectedBusinessUnitTags,
            visible: true,
            fixed: true,
            info: [],
            selectionType: "and"
          }
          this.documentSearch.metadata.push(tags);
        }
      }

      //Creo un deep copy del objeto
      this.userTagLists = JSON.parse(JSON.stringify(this.appTagLists));

      this.marketingService.filterDocuments().then((t: FilterData[]) => {
        //Aqui obtengo los tags y sus totales por cada Business Unit
        let itemCounts = 0;
        t.forEach((f: FilterData) => {
          itemCounts += f.count;

          //Selecciono la current unidad de Negocio del foreach
          //Quedarán seleccionadas en el filtro para usuarios no externos
          const currentBusinessUnit: Tag = this.appTagLists[0].tag.tags.filter((v: Tag) => {
            return v.name === f.businessUnit;
          })[0];
          const currentUserBusinessUnit: Tag = this.userTagLists[0].tag.tags.filter((v: Tag) => {
            return v.name === f.businessUnit;
          })[0];
          if (currentBusinessUnit && currentUserBusinessUnit) {
            currentUserBusinessUnit.selected = currentBusinessUnit.selected;
            currentUserBusinessUnit.selectionType = "and";
            currentUserBusinessUnit.count = f.count;
          }
          this.userBusinessUnit.push(currentUserBusinessUnit);


          /* Aqui administro los tags y marco cuales deben quedar visibles
             además clasifico cada total por unidad de negocio
          */
          //Hago un foreach por las propiedades: Patologias, Productos, Servicios, Tipo Doc
          //Para la current Unidad de negocio
          if(f.tag != null){
            Object.keys(f.tag).forEach((tagName: string) => {
              let tagsForCategory = f.tag[tagName];

              //Filtro la la categoría de tags el usuario
              const currentUserTagCategory: ClickableTagList = this.userTagLists.filter((ctag: ClickableTagList) => {
                return ctag.tag.name === tagName;
              })[0];

              if (currentUserTagCategory) {
                //Oculto los tags que no están informados en ningún documento
                //De las unidades de negocio del usuario
                currentUserTagCategory.tag.tags.forEach((value: Tag) => {
                  //Verifico que este tag este en algun item de la lista
                  //para la unidad de negocio actual
                  const exists = tagsForCategory.filter((e: Tag) => {
                    return e.id == value.id;
                  })[0];

                  value.selected = false;
                  value.visible = false;
                  if (exists) {
                    //informo los datos en el tag del usuario
                    value.info = value.info || [];
                    const currentTagInfo = value.info.filter((ti: TagInfo) => {
                      ti.businessUnit == currentUserBusinessUnit.name;
                    })[0];
                    if (!currentTagInfo) {
                      value.info.push({
                        businessUnit: currentUserBusinessUnit.name,
                        count: exists.count,
                        selected: false,
                        visible: true
                      });
                    }
                  }
                });
              }
            });
          }
        });

        this.createFilterTagsUserCollection();
        this.documentsTotalCount = itemCounts;
        this.isLoading = false;
      });
    });

    this.marketingService.getDocuments(this.documentSearch).then(async (docs: SearchResult) => {
      this.documents = docs.values;
      this.loaderService.hide();
    });
  }

  async getMetadata(): Promise<TagList[]> {
    const tags: TagList[] = await this.marketingService.getListTags();
    return tags;
  }

  createFilterTagsUserCollection(): void {
    //Armo una lista de las cada categoría del filtro según las Unidades de Negocio intervinientes
    this.tipoDocumentosTags = [];
    this.productosTags = [];
    this.serviciosTags = [];
    this.patologiasTags = [];
    this.userTagLists.forEach((f: ClickableTagList) => {
      f.tag.tags.forEach((t: Tag) => {
        if (!t.info) {
          return true;
        }
        t.visible = false;
        t.count = 0;
        if (t.info.length > 0) {
          let buToCount: string[] = t.info.map((ti: TagInfo) => {
            return ti.businessUnit;
          });
          if (this.documentSearch.metadata.length > 0) {
            var buFilter = this.documentSearch.metadata.filter((bu: TagList) => { return bu.name == 'UnidadDeNegocio' })[0];
            if (buFilter) {
              buToCount = buFilter.tags.map((v: Tag) => { return v.name; });
            }
          }
          t.count = t.info.filter((ti: TagInfo) => { return buToCount.indexOf(ti.businessUnit) != -1; })
            .reduce((acc: TagInfo, val: TagInfo) => {
              acc.count += val.count;
              return acc;
            }, { count: 0 } as TagInfo).count;
          if (t.count > 0) {
            t.visible = true;
          }
        }

        if (!t.visible) {
          return true;
        }

        switch (f.tag.name) {
          case 'TipoDeDocumento':
            if (!this.tipoDocumentosTags.filter((s: Tag) => t.id == t.id)[0]) {
              this.tipoDocumentosTags.push(t);
            };
            break;
          case 'Productos':
            if (!this.productosTags.filter((s: Tag) => t.id == t.id)[0]) {
              this.productosTags.push(t);
            };
            break;
          case 'Servicios':
            if (!this.serviciosTags.filter((s: Tag) => t.id == t.id)[0]) {
              this.serviciosTags.push(t);
            };
            break;
          case 'Patologias':
            if (!this.patologiasTags.filter((s: Tag) => t.id == t.id)[0]) {
              this.patologiasTags.push(t);
            };
            break;
        }
      });
    });
  }

  async loadDocuments(): Promise<void> {
    this.loaderService.show();
    this.documentSearch.skip_doc_id = 0;
    this.marketingService.getDocuments(this.documentSearch).then(async (docs: SearchResult) => {
      this.documents = docs.values;
      await this.loaderService.hide();
    });
    this.marketingService.countDocuments(this.documentSearch).then((totalDocs: number) => {
      this.documentsTotalCount = totalDocs;
    });
  }

  async addDocuments(): Promise<void> {
    this.load_more = false;
    await this.loaderService.show();
    this.documentSearch.skip_doc_id = this.documents[this.documents.length - 1].id;
    let lastRowDate = moment(this.documents[this.documents.length - 1].publish_date, 'MM/DD/YYYY hh:mm A');
    if (!lastRowDate.isValid()) {
      lastRowDate = moment(this.documents[this.documents.length - 1].publish_date, 'L');
    }
    if (lastRowDate.isValid()) {
      this.documentSearch.publicationDate = lastRowDate.format('YYYYMMDD HH:mm:ss');
    }
    this.documentSearch.pageNumber++;
    const queryResult: SearchResult = await this.marketingService.getDocuments(this.documentSearch);
    queryResult.values.forEach((v:DocumentInfo) => {
      if (!this.documents.filter((d:DocumentInfo) => { return d.id == v.id;})[0]) {
        this.documents.push(v);
      }
    });
    //this.documents = this.documents.concat(queryResult.values);
    this.loaderService.hide();
    this.load_more = true;
  }

  async search(): Promise<void> {
    this.documentSearch.pageNumber = 1;
    this.documentSearch.skip_doc_id = 0;
    this.documentSearch.publicationDate = '';
    this.documents = [];
    await this.loadDocuments();
  }

  async openTagList(tagList: ClickableTagList): Promise<void> {
    //const oldSelected: boolean = tagList.selected;
    //this.tagLists.forEach(tagList => tagList.selected = false);
    tagList.visible = !tagList.visible;
  }

  async addTag(listTags: TagList, tag: Tag): Promise<void> {
    if(!this.user.is_external_user || listTags.name != 'UnidadDeNegocio'){
      const currentTags: ClickableTagList = this.userTagLists.filter(clickableTagList => clickableTagList.tag.tags.indexOf(tag) != -1)[0];
      if (currentTags !== null) {
        let currentTagSelected: Tag = currentTags.tag.tags.filter(t => t.id === tag.id)[0];
        currentTagSelected.selected = true;
      }


      //Nuevo añadido
      const tagIsAlreadyAddedToSearch: boolean =
        this.documentSearch.metadata.filter(tagList => tagList.tags.indexOf(tag) != -1).length > 0;

      if (!tagIsAlreadyAddedToSearch) {
        if (this.documentSearch.metadata.some(item => item.name === listTags.name)) {
          this.documentSearch.metadata.find(x => x.name == listTags.name).tags.push(tag);
          this.documentSearch.pageNumber = 1;
          this.documentSearch.skip_doc_id = 0;
          this.documentSearch.publicationDate = '';
        } else {
          const tags: TagList = {
            name: listTags.name,
            id: listTags.id,
            tags: [tag],
            visible: true,
            fixed: false,
            info: [],
            selectionType: listTags.selectionType
          }
          this.documentSearch.skip_doc_id = 0;
          this.documentSearch.publicationDate = '';
          this.documentSearch.pageNumber = 1;
          this.documentSearch.metadata.push(tags);
        }
        this.loadDocuments();
        this.createFilterTagsUserCollection();
      }
      else {
        this.toastr.info("La etiqueta ya se ha añadido");
      }
    }
  }

  async removeTag(listTags: TagList, tag: Tag): Promise<void> {
    const currentTags: ClickableTagList = this.userTagLists.filter(clickableTagList => clickableTagList.tag.tags.indexOf(tag) != -1)[0];
    if (currentTags !== null) {
      let currentTagSelected: Tag = currentTags.tag.tags.filter(t => t.id === tag.id)[0];
      currentTagSelected.selected = false;
    }

    const parentTag: TagList = this.documentSearch.metadata.find(x => x.name == listTags.name);

    if (parentTag.tags.length > 1) {
      const index = parentTag.tags.indexOf(tag, 0);
      parentTag.tags.splice(index, 1);
    } else {
      const index = this.documentSearch.metadata.indexOf(listTags, 0);
      this.documentSearch.metadata.splice(index, 1);
    }

    this.documents = [];
    this.loadDocuments();
    this.createFilterTagsUserCollection();
  }

  async nextDocuments(event: Event): Promise<void> {
    await this.addDocuments();
  }

  getVisibleUserTags(tags: Tag[]) {
    const values = tags.filter((t: Tag) => {
      return t.visible === true;
    });
    return values;
  }
}
