import { Injectable } from "@angular/core";
import { ApiConstants } from "src/app/core/constants/api.constants";
import { ApiService } from "src/app/core/services/api.service";
import { Appointment } from "../models/Appointment.model";
import { ComercialDelegate } from "../models/Appointment/ComercialDelegate.model";
import { BusinessUnitList } from "../models/BusinessUnitList";

@Injectable()
export class AppointmentServices {
    constructor(private apiService: ApiService)
    {

    }

    getListBusinessUnits() : Promise<BusinessUnitList> {
        return this.apiService.get(ApiConstants.pathBusinessUnitsPreview);
    }

    getListComercialDelegates(business_unit: string) : Promise<ComercialDelegate[]> {
        return this.apiService.get(ApiConstants.pathComercialDelegatesPreview, {business_unit});
    }

    postNewAppointment(appointment: Appointment) : Promise<string> {
        return this.apiService.post(ApiConstants.pathNewAppointment, appointment);
    }
}