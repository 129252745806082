import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ForbiddenComponent } from "./forbidden/forbidden.component";
import { LoginExternalComponent } from "./login-external/login-external.component";
import { LoginComponent } from "./login/login.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { PublicComponent } from "./public.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";

const routes: Routes = [
    {
        path: 'public',
        component: PublicComponent,
        children: [
            {
                path: 'login',
                component: LoginComponent,
                data: {title: 'Acceso'}  
            },
            {
                path: 'login-external',
                component: LoginExternalComponent,
                data: {title: 'Acceso externo'} 
            },
            {
                path: 'reset-password/:id',
                component: ResetPasswordComponent,
                data: {title: 'Reestablecer contraseña'} 
            },
            {
                path: '404',
                component: NotFoundComponent,
                data: {title: 'Página no encontrada'} 
            },
            {
                path: '403',
                component: ForbiddenComponent,
                data: {title: 'Acceso prohibido'}
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PublicRoutingModule {}