import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from "@angular/router";
import { Guid } from "guid-typescript";
import { AppConfig } from "src/app/app.configuration";
import { User } from "src/app/core/models/user.model";
import { UserService } from "src/app/core/services/user.service";
import { DocumentInfo } from "src/app/logic/models/Document.model";
import { MarketingService } from "src/app/logic/services/marketing.services";
import { LoaderService } from "src/app/core/services/loader.service";
import Utils from "src/app/logic/utils/utils";

@Component({
    selector: 'document',
    templateUrl: './document.component.html',
  })
  export class DocumentComponent implements OnInit {
    documentImages: SafeUrl = "";
    id: number;

    public isExternalUser: boolean = false;
    public showDownload: boolean = false;
    public isPdf: boolean = false;
    private document: DocumentInfo;
    private user: User;

    @ViewChild('videoPlayer', {static: false}) videoPlayer: ElementRef;

    constructor(
      private marketingService: MarketingService,
      private route: ActivatedRoute,
      private userService: UserService,
      private loaderService: LoaderService
    ) { }

    async ngOnInit(): Promise<void> {
      this.loaderService.show();
      this.user = await this.userService.attemptCurrentUser();
      this.isExternalUser = this.user.is_external_user;

      this.route.params.subscribe(async (params) => {
        const id: string = params['id'];
        this.id = parseInt(id);
        this.document = await this.marketingService.getDocumentById(this.id);
        this.addMissingImages();
      });
    };

    public async onDocumentDownload() : Promise<void>{
      const blob: Blob = await this.marketingService.getDownloadDocument(this.id, true);
      const url = window.URL.createObjectURL(blob);

      Utils.createRefToDowloadFile(url, this.document.title);
    }

    async addMissingImages(): Promise<void> {
      //const webApiUrl: string = `${AppConfig.settings.webApi.url}`;
      const webApiUrl: string = "https://api.portal-fk.es/api";
      const blob: Blob = await this.marketingService.getDownloadDocument(this.id, false);
      const url = window.URL.createObjectURL(blob);
      if(this.document.titleLeaf.slice(-4) == ".mp4"){
        this.videoPlayer.nativeElement.src = url;
        this.videoPlayer.nativeElement.classList.remove("documentsHide")
      } else {
        this.isPdf = true;
        this.documentImages = url;
      }
      this.loaderService.hide();
    }

    async contentLoaded(){
      this.showDownload = true;
    }
  }
