import { Injectable } from "@angular/core";
import { ApiConstants } from "src/app/core/constants/api.constants";
import { ApiService } from "src/app/core/services/api.service";
import { DocumentSearch } from "../models/DocumentSearch.model";
import { DocumentInfo } from "../models/Document.model";
import { TagList } from "../models/TagList.model";
import { FilterData } from "../models/FilterData";
import { SearchResult } from "../models/SearchResult";
import { Guid } from "guid-typescript";

@Injectable()
export class MarketingService {
    constructor(private apiService: ApiService)
    {

    }

    public async getListTags() : Promise<TagList[]> {
        return await this.apiService.get(ApiConstants.pathMetadata);
    }

    public async getDocuments(search: DocumentSearch) : Promise<SearchResult> {
        return await this.apiService.post(ApiConstants.pathSearch, search);
    }

    public async getFavourites() : Promise<SearchResult> {
        return await this.apiService.get(ApiConstants.pathFavourites);
    }

    public async getFavouriteDetail(docUniqueId: Guid) : Promise<DocumentInfo> {
        return await this.apiService.get(ApiConstants.pathFavouriteDetail, {docUniqueId});
    }

    public async countDocuments(search: DocumentSearch) : Promise<number> {
        return await this.apiService.post(ApiConstants.pathSearchCount, search);
    }

    public async filterDocuments() : Promise<FilterData[]> {
        return await this.apiService.post(ApiConstants.pathFilterDocuments);
    }

    public async getDocumentPreview(idDocument: number) : Promise<Blob> {
        return await this.apiService.get(ApiConstants.pathDocumentPreview, { idDocument: idDocument });
    }

    public async getDownloadDocument(idDocument: number, fileDownload: boolean) : Promise<Blob> {
        return await this.apiService.getFile(ApiConstants.pathDownloadDocument, { idDocument: idDocument, fileDownload: fileDownload });
    }

    public async shareDocument(email: string, idDocument: number, document_name: string) : Promise<string> {
        return await this.apiService.post(ApiConstants.pathShareDocument, { email: email, idDocument: idDocument, document_name: document_name });
    }

    public async getDocumentById(idDocument: number) : Promise<DocumentInfo> {
        return await this.apiService.get(ApiConstants.getDocumentById, { id: idDocument });
    }
}
