import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    private readonly JwtTokenKey = 'jwtToken';
    private readonly JwtRefreshTokenKey = 'jwtRefreshToken';
    private readonly IsUserAuthenticatedKey = "IsUserAuthenticated";

    public getToken(): string {
        try
        {
            return window.localStorage[this.JwtTokenKey];
        }
        catch
        {
            return null;
        }
    }

    public setToken(token: string) {
        window.localStorage[this.JwtTokenKey] = token;
    }

    public getRefreshToken(): string {
        try
        {
            return window.localStorage[this.JwtRefreshTokenKey];
        }
        catch
        {
            return null;
        }
    }

    public setRefreshToken(refreshToken: string) {
        window.localStorage[this.JwtRefreshTokenKey] = refreshToken;
    }

    public isAuthenticated() : boolean {
        try{
            const authenticated: string = window.localStorage[this.IsUserAuthenticatedKey];
            if(authenticated) {
                return JSON.parse(authenticated);
            } else {
                return false;
            }
        }
        catch
        {
            return false;
        }
    }

    public setIsAuthenticated(isAuthenticated: boolean) {
        window.localStorage[this.IsUserAuthenticatedKey] = String(isAuthenticated);
    }

    purgeToken() {
        window.localStorage.removeItem(this.JwtTokenKey);
        window.localStorage.removeItem(this.JwtRefreshTokenKey);
        window.localStorage.removeItem(this.IsUserAuthenticatedKey);
    }

}