import { Component, OnInit } from "@angular/core";
import { LoaderService } from "src/app/core/services/loader.service";
import { User } from "src/app/core/models/user.model";
import { UserService } from "src/app/core/services/user.service";

import {
  ColDef,
  ColumnApi,
  GetRowIdFunc,
  GetRowIdParams,
  GridApi,
  GridReadyEvent,
} from "ag-grid-community";
import { MetricService } from "src/app/logic/services/metric.services";
import { MetricInfo } from "src/app/logic/models/Metric";
import { Month } from "src/app/logic/models/Month";
import { MetricSearch } from "src/app/logic/models/MetricSearch.model";
import { MetricUser } from "src/app/logic/models/MetricUser";
import { PopoverComponent } from "../popover/popover.component";
import Utils from "src/app/logic/utils/utils";
import { Guid } from "guid-typescript";

const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
  "Julio", "Agosto", "Septiembre", "Obtubre", "Noviembre", "Diciembre"];

@Component({
  selector: "metrics",
  templateUrl: "./metrics.component.html",
})


export class MetricsComponent implements OnInit {
  private gridApi!: GridApi<MetricInfo>;
  private gridColumnApi!: ColumnApi;
  public defaultColDef: ColDef = {
    resizable: true,
  };


  load_more: boolean = true;
  isLoading: boolean = true;
  user: User;
  businessUnit: string = "";

  monthSelector: Month[] = [];
  yearSelector: number[] = [];
  userSelector: MetricUser[] = [];

  metricSearch: MetricSearch = {
    businessUnitId: Guid.createEmpty(),
    month: -1,
    year: -1,
    userId: -1
  };

  mostViewChecked: boolean = false;
  mostFavouriteChecked: boolean = false;

  onGridReady(params: GridReadyEvent) {
    params.api.sizeColumnsToFit();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  autoSizeAll(skipHeader: boolean) {
    const allColumnIds: string[] = [];
    this.gridColumnApi.getColumns()!.forEach((column) => {
      allColumnIds.push(column.getId());
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds, true);
  }

  columnDefs: ColDef[] = [
    { headerName: "Título", field: "title", minWidth: 200, maxWidth: 360 },
    { headerName: "Visitas", field: "viewCount", minWidth: 80, maxWidth: 120, sortable: true, unSortIcon: true },
    { headerName: "Favoritos", field: "favouriteCount", minWidth: 100, maxWidth: 120, sortable: true, unSortIcon: true },
    { headerName: "Descargas", field: "downloadCount", minWidth: 100, maxWidth: 130, sortable: true, unSortIcon: true },
    { headerName: "Mes", field: "date", valueFormatter: this.monthFormatter, minWidth: 100, maxWidth: 100 },
    { headerName: "Año", field: "date", valueFormatter: params => params.data.date.getFullYear(), minWidth: 80, maxWidth: 80 },
    // {
    //   headerName: "Estado",
    //   field: "state",
    //   cellClass: cellClass,
    //   minWidth: 120, maxWidth: 120,
    //   cellRenderer: function (params) {
    //     if (params.data.documentState == 0) {
    //       return "Borrador";
    //     } else if (params.data.documentState == 3) {
    //       return "Eliminado";
    //     } else if (params.data.documentState == 2) {
    //       return "Retirado";
    //     } else if (params.data.documentState == 1) {
    //       return "Publicado";
    //     }
    //     //return '<span>' + params.value + '</span>';
    //   },
    // },
    {
      headerName: "Carátula",
      field: "preview",
      headerClass: 'textcenter',
      minWidth: 120, maxWidth: 120,
      cellRenderer: PopoverComponent,
      cellClass: "eye-cell",
    },
    {
      headerName: "Enlace",
      field: "url",
      headerClass: 'textcenter',
      minWidth: 80, maxWidth: 80,
      cellRenderer: function (params) {
        if (params.value == '') {
          return '<a href="javascript:void(0);"><i class="fas fa-paperclip"></i></a></span>';
        } else {
          return '<a target="_blank" href="' + params.value + '"><i class="fas fa-paperclip"></i></a></span>';
        }
      },
      cellClass: "link-cell",
    },

  ];

  public getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.docUniqueId;
  };

  constructor(
    private metricService: MetricService,
    private loaderService: LoaderService,
    private userService: UserService
  ) {}

  async ngOnInit(): Promise<void> {
    this.loaderService.show();

    this.userService.attemptCurrentUser().then((u: User) => {
      this.user = u;
      this.userService.initMetricUsers().then((metricUsers: MetricUser[]) => {
        let metricUserFound = metricUsers.find(m => m.username.toLowerCase() == this.user.email.toLowerCase());
        if (metricUserFound != undefined) {
          this.businessUnit = metricUserFound.businessUnitName;
          this.metricSearch.businessUnitId = metricUserFound.businessUnitId;

          this.metricService.getMetrics(this.metricSearch).then(async (metrics: MetricInfo[]) => {
            let dates: Date[] = [];
            metrics.forEach((metric: MetricInfo) => {
              dates.push(metric.date);
            });

            let uniqueDates = dates
              .map(s => s.getTime())
              .filter((s, i, a) => a.indexOf(s) == i)
              .map(s => new Date(s));

            this.monthSelector = [];
            this.yearSelector = [];
            this.userSelector = [];

            uniqueDates.forEach((uniqueDate: Date) => {
              if (this.monthSelector.find(m => m.monthNumber == uniqueDate.getMonth()) == undefined) {
                this.monthSelector.push({
                  monthNumber: uniqueDate.getMonth(),
                  monthName: this.monthFormatter(uniqueDate.getMonth())
                });
              }
              if (this.yearSelector.find(y => y == uniqueDate.getFullYear()) == undefined) {
                this.yearSelector.push(uniqueDate.getFullYear());
              }
            });

            this.monthSelector.sort((m1, m2) => m1.monthNumber - m2.monthNumber);
            this.yearSelector.sort((y1, y2) => y1 - y2);

            this.metricService.getMetricUsers(this.metricSearch.businessUnitId).then(async (metricUsers: MetricUser[]) => {
              this.userSelector = metricUsers;
            });
            this.updateMetrics();
          });
        }
      });
    });
  }

  updateMetrics() {
    this.metricService.getMetrics(this.metricSearch).then(async (metrics: MetricInfo[]) => {
      this.gridApi.setRowData(metrics);
      this.loaderService.hide();
      this.updateURLMetrics(metrics);
    });
  }

  updateURLMetrics(metrics: MetricInfo[]) {
    if (metrics.length > 0) {
      let completeUrlRequest = 0;
      metrics.forEach((metric: MetricInfo) => {
        this.metricService.getUrlMetrics(metric.docUniqueId).then(async (urlMetric: MetricInfo) => {
          if (urlMetric != null) {
            var rowNode = this.gridApi.getRowNode(metric.docUniqueId.toString());
            rowNode.data.documentId = urlMetric.documentId;
            rowNode.data.url = urlMetric.url;
          }
        }).finally(() => {
          completeUrlRequest++;
          if (completeUrlRequest === metrics.length) {
            const params = { force: true };
            this.gridApi.refreshCells(params);
            this.isLoading = false;
          }
        });
      });
    } else {
      this.isLoading = false;
    }
  }

  public async exportMetrics() : Promise<void>{
    await this.loaderService.show();

    const blob: Blob = await this.metricService.exportMetrics(this.metricSearch.businessUnitId);
    const url = window.URL.createObjectURL(blob);

    const now = new Date();
    const year = now.getFullYear();
    let month: any = now.getMonth() + 1;
    let day: any = now.getDate();
    let hour: any = now.getHours();
    let minute: any = now.getMinutes();
    let second: any = now.getSeconds();
    if (day < 10) day = '0' + day;
    if (month < 10) month = '0' + month;
    if (hour < 10) hour = '0' + hour;
    if (minute < 10) minute = '0' + minute;
    if (second < 10) second = '0' + second;
    const formatNow = year + month + day + '_' + hour + "_" + minute + "_" + second;
    var filename = "Metrics_" + formatNow;
    Utils.createRefToDowloadFile(url, filename);

    await this.loaderService.hide();
  }

  onMonthChange(monthValue) {
    this.isLoading = true;
    this.loaderService.show();
    this.metricSearch.month = Number(monthValue) + 1;
    this.updateMetrics();
  }

  onYearChange(yearValue) {
    this.isLoading = true;
    this.loaderService.show();
    this.metricSearch.year = Number(yearValue);
    this.updateMetrics();
  }

  onUserChange(userValue){
    this.isLoading = true;
    this.loaderService.show();
    this.metricSearch.userId = Number(userValue);
    this.updateMetrics();
  }

  onMostViews(isChecked: boolean){
    if(this.mostFavouriteChecked){
      this.gridColumnApi.applyColumnState({
        defaultState: { sort: null }
      });
      this.mostFavouriteChecked = false;
    }
    if (isChecked) {
      this.gridColumnApi.applyColumnState({
        state: [{ colId: 'viewCount', sort: 'desc' }]
      });
    } else {
      this.gridColumnApi.applyColumnState({
        defaultState: { sort: null }
      });
    }
  }

  onMostFavourite(isChecked: boolean){
    if(this.mostViewChecked){
      this.gridColumnApi.applyColumnState({
        defaultState: { sort: null }
      });
      this.mostViewChecked = false;
    }
    if (isChecked) {
      this.gridColumnApi.applyColumnState({
        state: [{ colId: 'favouriteCount', sort: 'desc' }]
      });
    } else {
      this.gridColumnApi.applyColumnState({
        defaultState: { sort: null }
      });
    }
  }

  monthFormatter(params) {
    if(params.data == null){
      return monthNames[params];
    } else {
      return monthNames[params.data.date.getMonth()];
    }
  }

  // cellClass(params: CellClassParams) {
  //   if (params.data.documentState == 0) {
  //     return "status";
  //   } else if (params.data.documentState == 3) {
  //     return "status status-danger";
  //   } else if (params.data.documentState == 2) {
  //     return "status status-alert";
  //   } else if (params.data.documentState == 1) {
  //     return "status status-success";
  //   }
  // }
}


