import { Component, OnInit } from '@angular/core';
import * as msal from "@azure/msal-browser";
import { MsalService } from '@azure/msal-angular';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { UserService } from 'src/app/core/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/core/models/user.model';

@Component({
  selector: 'login-external',
  template: '',
})
export class LoginExternalComponent implements OnInit {
  private urlRedirect: string;

  constructor(
    private msalService: MsalService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute, 
    private toastr: ToastrService) { 

    }

  async ngOnInit(): Promise<void> {
    try
    {
      // Url que el usuario ha intentado acceder antes de logear.
      this.route.queryParams.subscribe(params => {
        if (params['page']) {
          this.urlRedirect = params['page'];
        }
      });

      await this.authenticate();
    }
    catch(error)
    {
      this.toastr.error('No se ha podido autenticar al usuario', 'Error');
      await this.router.navigate(['/public/login']);
    }
  }

  private async authenticate() {
    const redirectResponse: msal.AuthenticationResult = await this.msalService.instance.handleRedirectPromise();
    if (redirectResponse !== null) {
      const user: User = await this.userService.externalLogin(redirectResponse);

      if (user) {
        this.toastr.success('Usuario autenticado', 'Éxito!');
        //await this.router.navigate(['/auth/marketing']);
        await this.router.navigateByUrl(this.urlRedirect);
      } else {
        this.toastr.error('No se ha podido autenticar al usuario', 'Error');
        await this.router.navigate(['/public/login']);
      }
    } else {
      // MSAL.js v2 exposes several account APIs, logic to determine which account to use is the responsibility of the developer
      const account = this.msalService.instance.getActiveAccount();
      const accessTokenRequest = {
        scopes: ["user.read"],
        account: account
      };
      if (account) {
        try{
          const accessTokenResponse = await this.msalService.instance.acquireTokenSilent(accessTokenRequest);
          this.userService.externalLogin(accessTokenResponse);
        } catch (error) {
          // acquire token silent failure, and send an interactive request
          if (error instanceof InteractionRequiredAuthError) {
            await this.msalService.instance.acquireTokenRedirect(accessTokenRequest);
          } else {
            throw error;
          }
        }
      } else {
        await this.msalService.instance.acquireTokenRedirect(accessTokenRequest);
      }
    }
  }
}