import * as moment from "moment";

export default class DateUtils {

    static getDateFormated(date: Date) : string {
        return date ? moment(new Date(date)).locale("es").format('DD MMMM YYYY') : "";
    }

    static getDateFormated2(date: string, formatOut: string, formatIn: string) : string {
        return date !== "" ? moment(date, formatIn).format(formatOut) : null;
    }

    static setDateWithHours(date: string, formatOut: string, formatIn: string, hours: string, minutes: string, seconds: string) : Date {
        const algo: string = moment(date, formatIn)
                                .add(hours, 'hours')
                                .add(minutes, 'minutes')
                                .add(seconds, 'seconds')
                                .locale("es")
                                .format(formatOut);

        return moment(algo).toDate();
        //return moment(algo).toDate();
    }
}