import { Injectable } from '@angular/core';

declare let gtag: (property: string, value: any, configs: any) => {}; // Declare ga as a function

@Injectable()
export class GoogleAnalyticsService {

  constructor() { }

  //create our event emitter to send our data to Google Analytics
    public eventEmitter(eventAction: string, eventCategory: string, eventLabel: string = null, eventValue: number = null) {
        gtag('event', eventAction, {
            'event_category': eventCategory,
            'event_label': eventLabel,
            'value': eventValue,
        });
    }
}
