import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from 'src/app/core/services/loader.service';
import { DocumentInfo } from 'src/app/logic/models/Document.model';
import { RequestResetPassword } from 'src/app/logic/models/User/request-reset-password.model';
import { MarketingService } from 'src/app/logic/services/marketing.services';
import { UserApiService } from 'src/app/logic/services/user.service';
import Utils from 'src/app/logic/utils/utils';

@Component({
  selector: 'share-document-modal',
  templateUrl: './share-document-modal.component.html',
})

export class ShareDocumentModalComponent implements OnInit {
    @Input() public document: DocumentInfo;

    public emailInputValue: string;
    public errors = {
        required: false,
        format: false,

        hasError(): boolean {
            return this.required || this.format;
        },
    };

    get invalidEmail(): boolean {
        return this.errors.format;
    }

    get requiredEmail(): boolean {
        return this.errors.required;
    }

    constructor(
        public bsModalRef: BsModalRef,
        private marketingService: MarketingService,
        private toastr: ToastrService,
        private loaderService: LoaderService,
    ){}

    public ngOnInit(): void {}

    public async onSubmitShareDocument() : Promise<void> {
        this.validatorEmail(this.emailInputValue);
        
        if (!this.errors.hasError()) {
            try {
                await this.loaderService.show();
                let message: string = await this.marketingService.shareDocument(this.emailInputValue, this.document.id, this.document.title);
                this.toastr.success(message, 'Exito!');
                await this.loaderService.hide();
                this.bsModalRef.hide();
            } catch (error) {
                this.toastr.error(error, 'error');
            } 
        }
    } 

    public onEmailValueChange(value) : void {
        this.validatorEmail(value);
    }

    private validatorEmail(emailValue: string) : void {
        const regularExpresionEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                
        /* Ponemos que no hay errores y limpiamos lo anterior para volver a verificar */
        this.errors.required = false;
        this.errors.format = false;

        if (!emailValue || emailValue.trim() == "") 
        {
            this.errors.required = true;
            return;
        }

        /*Que el email tenga el formato correcto*/ 
        if (!regularExpresionEmail.test(emailValue)) {
            this.errors.format = true;
            return;
        }
    }
}