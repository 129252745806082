export class DocumentExtension {
    public static readonly PDF: string = 'pdf';
    public static readonly EXCELX: string = 'xlsx';
    public static readonly EXCEL: string = 'xls';
    public static readonly WORDX: string = 'docx';
    public static readonly WORD: string = 'doc';
    public static readonly POWERPOINTX: string = 'pptx';
    public static readonly POWERPOINT: string = 'ppt';
    public static readonly POWERPOINTMACRO: string = 'pptm';
    public static readonly VIDEOMP4: string = 'mp4';
    public static readonly VIDEOAVI: string = 'avi';
    public static readonly IMAGENJPG: string = 'jpg';
    public static readonly IMAGENPNG: string = 'png';
}