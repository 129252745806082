import { Injectable } from "@angular/core";
import { Guid } from "guid-typescript";
import { ApiConstants } from "src/app/core/constants/api.constants";
import { ApiService } from "src/app/core/services/api.service";
import { Register } from "../models/register.model";
import { Hospital } from "../models/User/hospital.model";
import { RequestResetPassword } from "../models/User/request-reset-password.model";
import { ResetPassword } from "../models/User/reset-password.model";

@Injectable()
export class UserApiService {
    constructor(private apiService: ApiService)
    {

    }

    register(register: Register) : Promise<void> {
        return this.apiService.post(ApiConstants.pathRegister, register);
    }

    LoadHospitals(hospital_name: string, row_limit: number) : Promise<Hospital[]> {
        return this.apiService.get(ApiConstants.pathHospitals, {hospital_name, row_limit});
    }

    RequestResetPassword(email: RequestResetPassword) : Promise<string> {
        return this.apiService.get(ApiConstants.pathRequestResetPassword, email);
    }

    ResetPassword(resetPassword: ResetPassword) : Promise<void> {
        return this.apiService.post(ApiConstants.pathResetPassword, resetPassword);
    }

    public async manageFavourite(documentUniqueId: Guid) : Promise<void> {
      return await this.apiService.get(ApiConstants.pathManageFavourite, {documentUniqueId});
    }
}
