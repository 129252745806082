import { NgModule } from "@angular/core";
import { ForbiddenComponent } from "./forbidden/forbidden.component";
import { LoginComponent } from "./login/login.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { PublicRoutingModule } from "./public-routing.module";
import { PublicComponent } from "./public.component";
import { RememberModalComponent } from "./login/remember-modal.component";
import { RegisterModalComponent } from "./login/register-modal.component";
import { SharedModule } from "src/app/shared/shared.module";
import { ModalModule } from "ngx-bootstrap/modal";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { LoginExternalComponent } from "./login-external/login-external.component";
import { NgxTypeaheadModule } from "ngx-typeahead";


@NgModule({
    imports: [
        PublicRoutingModule,
        SharedModule,
        ModalModule.forRoot(),
        NgxTypeaheadModule
    ],
    declarations: [
        PublicComponent,
        LoginComponent,
        RememberModalComponent,
        RegisterModalComponent,        
        ForbiddenComponent,
        NotFoundComponent,
        ResetPasswordComponent,
        LoginExternalComponent
    ],
    entryComponents: [
        RememberModalComponent,
        RegisterModalComponent,       
        
    ]
})
export class PublicModule { }