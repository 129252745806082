import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Guid } from 'guid-typescript';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from 'src/app/core/services/loader.service';
import { ResetPassword } from 'src/app/logic/models/User/reset-password.model';
import { UserApiService } from 'src/app/logic/services/user.service';
import Utils from 'src/app/logic/utils/utils';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent implements OnInit {
  id: Guid;
  ResetPasswordForm: FormGroup;
  ErrorMessage: string;

  get invalidPassword(): boolean {
    return this.ResetPasswordForm.get('password').hasError('error');
  }
  
  constructor(
    private route: ActivatedRoute,
    private userApiService: UserApiService,
    private toastr: ToastrService,
    private router: Router, 
    private loaderService: LoaderService, 
    
    ){
      this.ResetPasswordForm = new FormGroup({
        password: new FormControl('', [Validators.required]),
        confirm_password: new FormControl('', [Validators.required]),
      });
  
      this.ResetPasswordForm.setValidators([
        this.patternValidatorPassword()
      ]);
    }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.setId(params['id']);
   });
  }

  close() {
  };

  async submitResetPasswordForm() : Promise<void> {
    const Form = this.ResetPasswordForm.value; 
    let message = null;

    if (this.ResetPasswordForm.valid) {
      
      const data: ResetPassword = {
        request_reset_password_id: this.id.toString(),
        password: this.ResetPasswordForm.controls['password'].value,
        confirm_password: this.ResetPasswordForm.controls['confirm_password'].value,
      };

      try {
        await this.loaderService.show();
        message = await this.userApiService.ResetPassword(data);
        await this.loaderService.hide();
        this.toastr.success(message, 'Exito!');
        await this.router.navigateByUrl('public/login');
      }
      catch(error) {
        this.toastr.error(error, 'Error');
        await Utils.delay(1000);
        await this.loaderService.hide();
      }
    }
  }

  setId(id: string) {
    this.id = Guid.parse(id);
  }

  patternValidatorPassword() : ValidatorFn {
    return (group: FormGroup) : ValidationErrors => {
      const regUpperCaseChar = new RegExp("(?=.*[A-Z])");
      const regHasNumber = new RegExp("(?=.*[0-9])");
      const regHasSpecialchar = new RegExp("(?=.*[\"!@#\$%\^\&*\)\(+=._-])");
      const maxLength = 6;
  
      let passwordInput = group.controls['password'];
      let errors = {};
      this.ErrorMessage = "";
      let arrayErrors = [];
      let hasError = false;
  
      if (!passwordInput.value) 
      {
        passwordInput.setErrors({required: true});
        return;
      }

      /*Que contenga almenos una mayuscula*/ 
      if (!regUpperCaseChar.test(passwordInput.value)) {
        arrayErrors.push("Una mayúscula");
        hasError = true;
      }
  
      /*Que contenga almenos un numero*/ 
      if (!regHasNumber.test(passwordInput.value)) {
        arrayErrors.push("un número");
        hasError = true;
      }
  
      /*Que contenga almenos un caracter especial*/ 
      if (!regHasSpecialchar.test(passwordInput.value)) {
        arrayErrors.push("un carácter especial");
        hasError = true;
      }
  
      /*Que contenga almenos 6 caracteres*/ 
      if (passwordInput.value.length < maxLength) {
        arrayErrors.push("un minimo de 6 caracteres");
        hasError = true;
      }

      /*Misma contraseña*/
      if (this.checkIfMatchingPasswords()) {
        arrayErrors.push("han de coincidir");
        hasError = true;
      }
  
      if (hasError)
        errors = {error: true};
      else
        errors = null;
  
      this.ErrorMessage = "* La contraseña debe tener almenos: " + arrayErrors.join(', ') + ".";
      passwordInput.setErrors(errors);
      return;
    };
  }

  checkIfMatchingPasswords() : boolean {
    let passwordInput = this.ResetPasswordForm.controls["password"],
    passwordConfirmationInput = this.ResetPasswordForm.controls["confirm_password"];

    return passwordInput.value !== passwordConfirmationInput.value;
  }
}