import { Component, Input, OnDestroy, OnInit, SecurityContext } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { User } from "src/app/core/models/user.model";
import { DocumentInfo } from "src/app/logic/models/Document.model";
import { MarketingService } from "src/app/logic/services/marketing.services";
import DateUtils from "src/app/logic/utils/date.utils";
import StringUtils from "src/app/logic/utils/string.utiles";
import Utils from "src/app/logic/utils/utils";
import { GoogleAnalyticsService } from "../../google-analytics/gooogle-analytics.service";
import { DocumentExtension } from "./document-extension";
import { DomSanitizer } from '@angular/platform-browser';
import { UserApiService } from "src/app/logic/services/user.service";
import { ToastrService } from "ngx-toastr";
import { LoaderService } from "src/app/core/services/loader.service";

@Component({
    selector: 'fr-document-card',
    templateUrl: './document-card.component.html',
  })
  export class DocumentCardComponent implements OnInit, OnDestroy {
    isExternalUser: boolean = false;
    imageLoaded: boolean = false;
    icon: string;

    @Input() document: DocumentInfo;
    @Input() image: string;
    @Input() user: User;

    constructor(
      private marketingService: MarketingService,
      private userService: UserApiService,
      private modalService: BsModalService,
      private sanitizer: DomSanitizer,
      private toastr: ToastrService,
      private loaderService: LoaderService,
      private googleAnalyticsService: GoogleAnalyticsService,
      ) { }

    public async ngOnInit(): Promise<void> {
      if(this.document.id > 0){
        this.marketingService.getDocumentPreview(this.document.id).then((b64: object) => {
          const img = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + b64));
          this.image = img;
          this.imageLoaded = true;
        });
        this.isExternalUser = this.user.is_external_user;
        this.setFadeInIconByExtension();
      }
    }

    public ngOnDestroy(): void { }

    public getDateFormat(date: Date) : string {
        return DateUtils.getDateFormated(date);
    }

    public async onDocumentFavourite() : Promise<void>{
      await this.loaderService.show();
      try {
        await this.userService.manageFavourite(this.document.uniqueId);
        await this.loaderService.hide();
        this.document.isFav = !this.document.isFav;
      }
      catch(error) {
        this.toastr.error(error, 'Error');
        await this.loaderService.hide();
      }
    }

    public async onDocumentDownload() : Promise<void>{
      const blob: Blob = await this.marketingService.getDownloadDocument(this.document.id, true);
      const url = window.URL.createObjectURL(blob);

      Utils.createRefToDowloadFile(url, this.document.title);
    }

    public onClickViewDocument() : void {
      // Notificar a google analytics
      this.googleAnalyticsService.eventEmitter(this.document.id.toString(), 'document_view', this.document.title);
    }

    /*public async onShareDocument(): Promise<void> {
      const initialState = { document :  this.document};
      const modalRef: BsModalRef = this.modalService.show(ShareDocumentModalComponent, {initialState});
    }*/

    private setFadeInIconByExtension() : void {
      if (!this.document.titleLeaf) {
        return;
      }
      const extension: string = StringUtils.getExtension(this.document.titleLeaf);
      const src: string = this.getFadeInIconByExtension(extension);
      this.icon = src;
    }

    private getFadeInIconByExtension(extension: string) : string {
      const PATHTOIMAGES =  "../assets/img/iconos";
      switch(extension.toLowerCase()) {
        case DocumentExtension.WORDX:
        case DocumentExtension.WORD: {
          return `${PATHTOIMAGES}/01word.svg`;
        }
        case DocumentExtension.EXCELX:
        case DocumentExtension.EXCEL: {
          return `${PATHTOIMAGES}/02excel.svg`;
        }
        case DocumentExtension.PDF: {
          return `${PATHTOIMAGES}/03pdf.svg`;
        }
        case DocumentExtension.POWERPOINTX:
        case DocumentExtension.POWERPOINT:
        case DocumentExtension.POWERPOINTMACRO: {
          return `${PATHTOIMAGES}/04ppt.svg`;
        }
        case DocumentExtension.VIDEOMP4:
        case DocumentExtension.VIDEOAVI:{
          return `${PATHTOIMAGES}/05video.svg`;
        }
        case DocumentExtension.IMAGENJPG:
        case DocumentExtension.IMAGENPNG:{
          return `${PATHTOIMAGES}/06image.svg`;
        }
        default: {
          return `${PATHTOIMAGES}/07default.svg`;
        }
     }
    }

    public onImgError(event): void {
      const extension: string = StringUtils.getExtension(this.document.title);
      const src: string = this.getPortafolioImgByExtension(extension);
      event.target.src = src;
     }

     private getPortafolioImgByExtension(extension: string): string {
        const PATHTOPORTAFOLIO =  "../assets/img/portfolio";
        switch(extension.toLowerCase()) {
          case DocumentExtension.WORDX:
          case DocumentExtension.WORD: {
            return `${PATHTOPORTAFOLIO}/imagen.svg`;
          }
          case DocumentExtension.EXCELX:
          case DocumentExtension.EXCEL: {
            return `${PATHTOPORTAFOLIO}/imgExcel.svg`;
          }
          case DocumentExtension.PDF: {
            return `${PATHTOPORTAFOLIO}/imgPdf.svg`;
          }
          case DocumentExtension.POWERPOINTX:
          case DocumentExtension.POWERPOINT:
          case DocumentExtension.POWERPOINTMACRO: {
            return `${PATHTOPORTAFOLIO}/imgPpt.svg`;
          }
          case DocumentExtension.VIDEOMP4:
          case DocumentExtension.VIDEOAVI:{
            return `${PATHTOPORTAFOLIO}/imgVideo.svg`;
          }
          case DocumentExtension.IMAGENJPG:
          case DocumentExtension.IMAGENPNG:{
            return `${PATHTOPORTAFOLIO}/imgPhoto.svg`;
          }
          default: {
            return `${PATHTOPORTAFOLIO}/imagen.svg`;
          }
      }
     }
  }
