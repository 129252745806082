import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, ReplaySubject } from "rxjs";
import { ApiConstants } from "../constants/api.constants";
import { Auth } from "../models/auth.model";
import { ApiService } from "./api.service";
import { LocalStorageService } from "./local-storage.service";
import * as msal from "@azure/msal-browser";
import { User } from "../models/user.model";
import { MetricUser } from "src/app/logic/models/MetricUser";

@Injectable()
export class UserService {
    private currentUserSubject = new BehaviorSubject<User>(null);
    private isAuthenticatedSubject = new ReplaySubject<boolean>(1);

    public isAuthenticated: Observable<boolean> = this.isAuthenticatedSubject.asObservable();

    constructor(
        private apiService: ApiService,
        private localStorageService: LocalStorageService
    ) { }

    public getCurrentUser() : User {
        return this.currentUserSubject.getValue();
    }

    public async populate() {
        if(this.localStorageService.getToken()) {
            try {
                const data = await this.attemptUser();
                this.setUser(data);
            }
            catch(error) {
                this.purgeAuth();
            }
        }
        else
        {
            this.purgeAuth();
        }
    }

    public async attemptAuth(user: string, password: string) : Promise<User> {
        const pwd = /*toBase64(*/password/*)*/;
        const data: Auth = await this.apiService.authToken(user, pwd) as Auth;
        this.setUserByToken(data);
        const usr: User = await this.attemptUser();
        if(usr) {
            this.localStorageService.setIsAuthenticated(true);
            this.setUser(usr);
        }
        return usr;
    }

    public async externalLogin(authenticationResult: msal.AuthenticationResult) : Promise<User> {
        const data: Auth = await this.apiService.post(ApiConstants.pathExternalUser, authenticationResult);
        if (data) {
            this.setUserByToken(data);
            const user: User = await this.attemptUser();
            if(user) {
                this.localStorageService.setIsAuthenticated(true);
                this.setUser(user);
            }
            return user;
        } else {
            return null
        }
    }

    public async purgeAuth() {
        this.localStorageService.purgeToken();
        this.purgeUser();
    }

    private async attemptUser() : Promise<User> {
        return await this.apiService.get(ApiConstants.pathUser);
    }

    public async attemptCurrentUser(): Promise<User> {
        try{
            if(this.localStorageService.isAuthenticated()) {
                return await this.attemptUser();
            }
            else
            {
                return null;
            }
          }catch(error) {
            return null;
          }
    }

    private async setUser(user: User) {
        this.currentUserSubject.next(user);
        this.isAuthenticatedSubject.next(true);
    }

    private async purgeUser() {
        this.currentUserSubject.next(null);
        this.isAuthenticatedSubject.next(false);
    }

    private setUserByToken(data: Auth) {
        this.localStorageService.setToken(data.access_token);
        this.localStorageService.setRefreshToken(data.refresh_token);
    }

    public async initMetricUsers() : Promise<MetricUser[]> {
      return await this.apiService.get(ApiConstants.pathInitMetricUsers);
    }
}
