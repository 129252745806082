import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from "@angular/router";
import { LocalStorageService } from "src/app/core/services/local-storage.service";

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    
    constructor(
        private router: Router,
        private localStorageService: LocalStorageService
    ) { }
    
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return  this.can(route, state);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.can(route, state);
    }

    can(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const isUserAuth: boolean = this.localStorageService.isAuthenticated();

        if(!isUserAuth) {

            this.router.navigate(['/'], { queryParams: { page: state.url } });
        }
        return true;
    }
}