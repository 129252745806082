import { DocumentInfo } from "../models/Document.model";

export default class Utils {

    static delay(ms: number)
    {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    static createRefToDowloadFile(url: string, documentTitle: string)
    {
        let a: HTMLAnchorElement = document.createElement('a');
        a.href = url; 
        a.target = '_blank';
        a.download = documentTitle;
        document.body.appendChild(a);
        a.click()
        a.remove();
    }
}