import { CommonModule, DatePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { DocumentCardComponent } from "./components/document-card/document-card.component";
import { ShareDocumentModalComponent } from "./components/document-card/share-document-modal.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { GoogleAnalyticsService } from "./google-analytics/gooogle-analytics.service";
import { FooterComponent } from "./layout/footer/footer.component";
import { HeaderComponent } from "./layout/header/header.component";
import { LayoutComponent } from "./layout/layout.component";
import { SecurePipe } from "./pipes/secure.pipe";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule
    ],
    declarations: [
        HeaderComponent,
        FooterComponent,
        LayoutComponent,
        LoaderComponent,
        SecurePipe,
        DocumentCardComponent,
        ShareDocumentModalComponent
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        LayoutComponent,
        LoaderComponent,
        SecurePipe,
        DocumentCardComponent
    ],
    entryComponents: [
        ShareDocumentModalComponent,  
    ],
    providers: [
        DatePipe,
        GoogleAnalyticsService
    ]
})
export class SharedModule { }