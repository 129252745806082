import { Component, OnInit } from '@angular/core';
import { DocumentSearch } from 'src/app/logic/models/DocumentSearch.model';
import { Tag } from 'src/app/logic/models/Tag.model';
import { MarketingService } from 'src/app/logic/services/marketing.services';
import { DocumentInfo } from 'src/app/logic/models/Document.model';
import { TagList } from 'src/app/logic/models/TagList.model';
import { LoaderService } from 'src/app/core/services/loader.service';
import { User } from 'src/app/core/models/user.model';
import { UserService } from 'src/app/core/services/user.service';
import { AppConfig } from 'src/app/app.configuration';
import { SearchResult } from 'src/app/logic/models/SearchResult';

class ClickableTagList {
  public tag: TagList;
  public selected: boolean;
  public visible: boolean;
  public selectionType: string;
}

@Component({
  selector: 'favourite',
  templateUrl: './favourite.component.html',
})
export class FavouriteComponent implements OnInit {
  documents: DocumentInfo[] = [];
  documentsTotalCount: number = 0;
  userTagLists: ClickableTagList[] = [];
  favouriteTags: Tag[] = [];

  documentSearch: DocumentSearch = {
    search: "",
    metadata: [],
    skip_doc_id: 0,
    searchJustByTitle: false,
    publicationDate: '',
    pageNumber: 1,
    take: AppConfig.settings.document.numberOfFavourites
  };

  load_more: boolean = true;
  isLoading: boolean = true;
  user: User;

  constructor(
    private marketingService: MarketingService,
    private loaderService: LoaderService,
    private userService: UserService) { }

  async ngOnInit(): Promise<void> {
    this.loaderService.show();

    this.userService.attemptCurrentUser().then((u: User) => {
      this.user = u;
      this.marketingService.getFavourites().then(async (docs: SearchResult) => {
        this.documents = docs.values;
        this.documentsTotalCount = docs.totalCount;
        docs.values.forEach((doc: DocumentInfo) => {
          const tag: Tag = {
            id: doc.id.toString(),
            name: doc.title,
            visible: true,
            count: 1,
            selected: true,
            fixed: true,
            selectionType: "and",
            info: []
          }
          this.favouriteTags.push(tag);
        });
        const tags: TagList = {
          name: "Favoritos",
          id: null,
          tags: this.favouriteTags,
          visible: true,
          fixed: true,
          info: [],
          selectionType: "and"
        }

        const clickTags: ClickableTagList = {
          tag: tags,
          selected: true,
          visible: true,
          selectionType: "and"
        }
        this.userTagLists.push(clickTags);
        this.isLoading = false;
        this.loaderService.hide();
        let completeRequest = 0;
        this.documents.forEach((doc: DocumentInfo) => {
          this.marketingService.getFavouriteDetail(doc.uniqueId).then(async (docInfo: DocumentInfo) => {
            if (docInfo != null) {
              var foundIndex = this.documents.findIndex(d => d.uniqueId == doc.uniqueId);
              this.documents[foundIndex] = docInfo;
              clickTags.tag.tags.find(t => t.name == docInfo.title).id = docInfo.id.toString();
            }
          }).finally(() => {
            completeRequest++;
            if (completeRequest === this.documents.length) {
              this.userTagLists = [];
              this.userTagLists.push(clickTags);
            }
          });
        });
      });
    });

  }

  async openTagList(tagList: ClickableTagList): Promise<void> {
    tagList.visible = !tagList.visible;
  }

  getVisibleUserTags(tags: Tag[]) {
    const values = tags.filter((t: Tag) => {
      return t.visible === true;
    });
    return values;
  }
}
