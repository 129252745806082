import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "src/app/logic/guards/auth.guard";
import { AppointmentComponent } from "./appointment/appointment.component";
import { AuthComponent } from "./auth.component";
import { DocumentComponent } from "./document/document.component";
import { MarketingComponent } from "./marketing/marketing.component";
import { FavouriteComponent } from "./favourite/favourite.component";
import { MetricsComponent } from "./metrics/metrics.component";


const routes: Routes = [
    {
        path: 'auth',
        component: AuthComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'marketing',
                component: MarketingComponent,
                data: {title: 'Buscador de documentos'}
            },
            {
              path: 'metrics',
              component: MetricsComponent,
              data: {title: 'Métricas'}
            },
            {
              path: 'favourite',
              component: FavouriteComponent,
              data: {title: 'Documentos favoritos'}
            },
            {
                path: 'appointment',
                component: AppointmentComponent,
                data: {title: 'Solicitar una visita'}
            },
            {
                path: 'document/:id',
                component: DocumentComponent,
                data: {title: 'Visualizar documento'}
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthRoutingModule {}
