import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule, Optional, SkipSelf } from "@angular/core";
import { throwIfAlreadyLoaded } from "../core/guards/module-import.guard";
import { AuthGuard } from "./guards/auth.guard";
import { UserApiService } from "./services/user.service";
import { MarketingService } from "./services/marketing.services";
import { AppointmentServices } from "./services/appointment.services";
import { MetricService } from "./services/metric.services";

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule
    ],
    providers: [
        AuthGuard,
        MetricService,
        UserApiService,
        MarketingService,
        AppointmentServices
    ]
})
export class LogicModule {
    constructor(@Optional() @SkipSelf() parentModule: LogicModule) {
        throwIfAlreadyLoaded(parentModule, 'LogicModule');
    }

}
