import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './modules/auth/auth.module';
import { PublicModule } from './modules/public/public.module';
import { SharedModule } from './shared/shared.module';
import { LogicModule } from './logic/logic.module';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AppConfig } from './app.configuration';
import { MsalModule, MsalService, MSAL_INSTANCE } from '@azure/msal-angular';
import { BrowserCacheLocation, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';

export function MSALInstanceFactory(): IPublicClientApplication {
  return  new PublicClientApplication({ // MSAL Configuration
    auth: {
        clientId: AppConfig.settings.azure.clientId,
        authority: AppConfig.settings.azure.authority,
        redirectUri: AppConfig.settings.azure.redirectUri,
        //postLogoutRedirectUri: "http://localhost:4200/",
        navigateToLoginRequestUrl: true
    },
    cache: {
        cacheLocation : BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: true, // set to true for IE 11
    }
  });
}

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    AppRoutingModule,
    PublicModule,
    AuthModule,
    SharedModule,
    LogicModule,
    CoreModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    MsalModule
  ],
  providers: [
    AppConfig,
    { 
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig], 
      multi: true 
    },
    MsalService,
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }